<template>
  <div></div>
</template>

<script>
import { Auth } from '@aws-amplify/auth';

export default {
  name: 'Logout',
  async created() {
    try {
      await Auth.signOut({ global: true });
      this.$currentUserRole = 'user';
      await this.$router.push('/webmag-login');
    } catch (error) {
      console.error('Logout was not successful', error);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
